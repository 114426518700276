import { useMutation, useQueryClient } from "@tanstack/react-query";

import { httpClient } from "@/api/client";
import { itRecommendationQueryKey } from "./use-it-recommendation";

type SubmitITRecommendationVariables = {
  submissionId: string;
};

export function useITRecommendationTypeFormQuizMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables: SubmitITRecommendationVariables) =>
      httpClient.paths["/v1/typeform/quiz"].post(null, variables),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [itRecommendationQueryKey.itRecommendation],
      }),
  });
}
