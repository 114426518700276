import { captureException } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import type { AxiosError } from "axios";

import { httpClient } from "@/api/client";

export const itRecommendationQueryKey = {
  itRecommendation: "itRecommendation",
};

type UseITRecommendationVariables = {
  submissionId: string;
  identifier: string;
};

export function useITRecommendation(variables: UseITRecommendationVariables) {
  return useQuery({
    queryKey: [itRecommendationQueryKey.itRecommendation],
    queryFn: async () => {
      try {
        const { data } = await httpClient.paths[
          "/v1/typeform/recommendation"
        ].get({
          submissionId: variables.submissionId,
        });

        return data;
      } catch (error) {
        const EXCLUDED_ERRORS = new Set([400, 404]);

        if (!EXCLUDED_ERRORS.has((error as AxiosError).response?.status || 0)) {
          captureException(error, {
            contexts: {
              ...(variables.submissionId && {
                recommendation: {
                  submissionId: variables.submissionId,
                },
              }),
            },
          });
        }

        throw error;
      }
    },
    retry: 3, // In some cases the backend might not be able to immediately provide the recommendation
    retryDelay: 3000,
    enabled: Boolean(variables.submissionId || variables.identifier),
    staleTime: Number.POSITIVE_INFINITY, // User can only have one recommendation at a time
  });
}
