import cookie from "js-cookie";

const SUBMISSION_ID_COOKIE_NAME = "GS_REC_SUBMISSION_ID";

function getDomain() {
  // To make the cookie available on all subdomains, we need to set the domain to the root domain
  // Ex.: www.example.com -> example.com
  return document.location.hostname.split(".").slice(-2).join(".");
}

export function saveSubmissionId(submissionId: string) {
  cookie.set(SUBMISSION_ID_COOKIE_NAME, submissionId, {
    expires: 1,
    sameSite: "lax",
    path: "/",
    domain: getDomain(),
    secure: import.meta.env.PROD,
  });
}

export function getSubmissionId() {
  return cookie.get(SUBMISSION_ID_COOKIE_NAME) || "";
}

export function removeSubmissionId() {
  cookie.remove(SUBMISSION_ID_COOKIE_NAME, { path: "/", domain: getDomain() });
}
