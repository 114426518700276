import { useQuery } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import {
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react";

import { api } from "@/api/client";
import {
  getSubmissionId,
  removeSubmissionId,
} from "@/components/organisms/ITRecommendation/utils";
import { useITRecommendationTypeFormQuizMutation } from "@/hooks/use-it-recommendation-mutation";
import { SetUser } from "@/third-party/amplitude";
import { getAccessToken, setupAxios401Interceptor } from "@/utils/auth";
import { randomProfileColorPicker } from "@/utils/profile-colors";
import type { User } from "types";

type UserRole = "admin" | "customer";

type AuthContextProps = {
  user: User | null | undefined;
  isLoadingUser: boolean;
  role: UserRole;
  setRole: Dispatch<SetStateAction<UserRole>>;
  setIsSignedIn: Dispatch<SetStateAction<boolean>>;
};

export const CURRENT_USER_QUERYKEY = ["currentUser"];

function useUser({ enabled }: { enabled: boolean }) {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: CURRENT_USER_QUERYKEY,
    structuralSharing: false,
    queryFn: async () => {
      const { data } = await axios.get<User>("/users/me");
      data.randomProfileColor = randomProfileColorPicker(data.email);

      setupAxios401Interceptor(queryClient);
      return data;
    },
    enabled,
    staleTime: Number.POSITIVE_INFINITY,
  });
}

const AuthContext = createContext<AuthContextProps | null>(null);

const AuthProvider = (props: PropsWithChildren) => {
  const accessToken = getAccessToken();
  const [isSignedIn, setIsSignedIn] = useState(!!accessToken);

  // Set the Authorization header for dev environment
  if (import.meta.env.DEV && isSignedIn) {
    // TODO: Remove this after direct usage of axios is cleaned up
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    api.client.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  const {
    data: user,
    isLoading: isLoadingUser,
    isError,
  } = useUser({ enabled: isSignedIn });

  const [role, setRole] = useState<"admin" | "customer">("customer");

  if (user) {
    SetUser(user);
  }

  const { mutateAsync } = useITRecommendationTypeFormQuizMutation();

  // If the user submitted the IT Recommendation Typeform quiz while not signed in,
  // associate the submission with the user after they sign in
  useEffect(() => {
    const submissionId = getSubmissionId();

    if (user && submissionId) {
      mutateAsync({ submissionId }).then(removeSubmissionId);
    }
  }, [user, mutateAsync]);

  useEffect(() => {
    if (user?.isAdmin) {
      setRole("admin");
    } else {
      setRole("customer");
    }
  }, [user?.isAdmin]);

  useEffect(() => {
    if (isError) {
      setIsSignedIn(false);
    }
  }, [isError]);

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoadingUser,
        role,
        setRole,
        setIsSignedIn,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
